import React from 'react';
import { Link } from 'react-router-dom';

import Content from '../../components/Content';
import Hero from '../../components/Hero';
import PageTitle from '../../components/PageTitle';

import pricingHero from './pricing-hero.jpg';

import './Pricing.scss';

const Pricing = () => (
  <>
    <PageTitle title="Pricing" />
    <Hero>
      <img src={pricingHero} alt="Bex using the weighted ropes around a tree by a stream" />
    </Hero>
    <Content>
      <h1 className="mainHeading">Pricing</h1>
      <div className="pricing">
        <table className="pricing__timetable" cellPadding={0} cellSpacing={0}>
          <caption className="timetable__caption">Timetable pricing</caption>
          <thead className="timetable__head">
            <tr>
              <th rowSpan={2}><span className="visuallyHidden">Day</span></th>
              <th colSpan={3}><span className="visuallyHidden">Time</span></th>
              <th className="timeLast"></th>
            </tr>
            <tr>
              <th><span className="timeHolder">06:30</span></th>
              <th><span className="timeHolder">09:00</span></th>
              <th><span className="timeHolder">18:00</span></th>
              <th className="timeLast"><span className="timeHolder">21:00</span></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="day">Weekday</td>
              <td className="peak peak--morning"><span className="visuallyHidden">06:00 until 09:00 </span>Peak</td>
              <td className="peak peak--offPeak"><span className="visuallyHidden">09:00 until 18:00 </span>Off-Peak</td>
              <td className="peak"><span className="visuallyHidden">18:00 until 21:00 </span>Peak</td>
              <th className="timeLast"></th>
            </tr>
            <tr>
              <td className="day">Saturdays</td>
              <td className="peak peak--sat" colSpan={3}><span className="visuallyHidden">06:30 until 13:00 </span>Peak</td>
              <th className="timeLast"></th>
            </tr>
          </tbody>
        </table>

        <table className="pricing__sessions">
          <thead>
            <tr>
              <th rowSpan={2}><span className="visuallyHidden">Session length</span></th>
              <th colSpan={2}><span className="visuallyHidden">Pricing options</span></th>
            </tr>
            <tr>
              <th>Off-Peak</th>
              <th>Peak</th>
            </tr>
          </thead>
          <tbody>
            <tr className="cells30Min">
              <td><span className="circle circle--30Min">30 minute 1-to-1 session</span></td>
              <td className="sessionPrice"><span className="peak peak--offPeak">£31.50</span></td>
              <td className="sessionPrice"><span className="peak">£35.00</span></td>
            </tr>
            <tr>
              <td rowSpan={2}><span className="circle circle--60Min">60 minute 1-to-1 session</span></td>
              <td className="sessionPrice"><span className="peak peak--offPeak">£52.50</span></td>
              <td className="sessionPrice"><span className="peak">£57.00</span></td>
            </tr>
            <tr>
              <td className="sessionPrice"><span className="peak peak--offPeak">5 sessions for only:<br />£250.00</span><span className="bestValue"><span className="bestValue--inner">Best Value</span></span></td>
              <td className="sessionPrice"><span className="peak">5 sessions for only:<br />£275.00</span></td>
            </tr>
          </tbody>
        </table>

        <p className="pricing__text__cta"><Link className="pricing__text__cta__button" to="/contact">Book now</Link></p>
        <p className="pricing__text__footnote"><small>Note: for partner and small group personal training sessions, please <Link to="/contact">contact us</Link> for a quote.</small></p>
        
        <hr className="pricing__hr" />
        
        <div className="pricing__join">
          <div className="pricing__join__description">            
            <p className="pricing__join__description__title">Classes</p>
            
           
            <p className="pricing__join__description__meta">
            <strong>Outdoor Circuit Training</strong><br />Saturdays 9:45am - 10:30am
            </p>
            <p>Our classes take place at <a href="http://www.cheshammoorfitness.org.uk/">Chesham Moor Gym</a> and are £10.00 per session.</p>
            <p>If you want to get fit in a fun and friendly environment, these classes are for you! Our instructors will adapt each exercise to suit your ability level, and we keep our group classes to a maximum of 15 so we can give each person the support they need. </p>

          </div>
          <div className="pricing__join__map">
            <iframe
              title="Google Map of the Chesham Moor Gym & Swim location"
              className="chesham-moor-location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2472.9655851594093!2d-0.6092557837206027!3d51.697074879666644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48765d71eeeeff41%3A0xccd7ca71720aa5b5!2sChesham+Moor+Gym+%26+Swim!5e0!3m2!1sen!2suk!4v1468180159650"
              width="400"
              height="450"
              frameBorder="0"
              style={{ border:0 }}
              allowFullScreen=""
            />
          </div>
        </div>

      </div>
    </Content>
  </>
);

export default Pricing;
